import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TruncatePipe } from '../../../../../../shared/pipes/truncate/truncate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';
import { SvgIconComponent } from 'angular-svg-icon';
import { TooltipModule } from 'primeng/tooltip';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgClass,
        TooltipModule,
        NgIf,
        SvgIconComponent,
        MatTooltip,
        TranslateModule,
        TruncatePipe,
    ],
})
export class CheckboxComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() options: any = {};
}
