import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { tabTitle } from './core/configurations/configurations';
import { NotFoundTemplateComponent } from './modules/not-found-template/not-found-template.component';
import { ForbiddenComponent } from './modules/forbidden/forbidden.component';

const routes: Routes = [
  {
    path: 'legal',
    loadChildren: () =>
      import('./modules/legal/legal-routing.module').then((m) => m.LegalRoutingModule),
  },
  {
    path: 'auto-login',
    loadChildren: () =>
      import('./modules/auto-login/auto-login-routing.module').then(
        (m) => m.AutoLoginRoutingModule
      ),
  },
  {
    path: 'rail-app/login',
    loadChildren: () =>
      import('./modules/auto-login-rail-app/auto-login-rail-app-routing.module').then(
        (m) => m.AutoLoginRailAppRoutingModule
      ),
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/application/application-routing.module').then(
        (m) => m.ApplicationRoutingModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/auth/auth-routing.module').then((m) => m.AuthRoutingModule),
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    data: { title: tabTitle.forbidden },
  },
  {
    path: '**',
    component: NotFoundTemplateComponent,
    data: { title: tabTitle.notFound },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
