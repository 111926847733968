import { Component, forwardRef, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorsFieldsComponent } from '../errors-fields/errors-fields.component';
import { NgClass, UpperCasePipe } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
    selector: 'app-input-iata',
    templateUrl: './input-iata.component.html',
    styleUrls: ['./input-iata.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        SvgIconComponent,
        NgClass,
        forwardRef(() => ErrorsFieldsComponent),
        TranslateModule,
        UpperCasePipe,
    ],
})
export class InputIataComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() type = 'text';
  @Input() options: any = {};
  @Input() inputs: any;
}
