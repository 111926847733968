import { Injectable } from '@angular/core';
import { compress, decompress } from 'lz-string';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {
  }
  decodeToken(token) {
    if (!token || token === '') {
      return '';
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  }
  // Limpia el avail guardado
  removeAvail(key: string) {
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i)?.includes(key + '-result')) {
        localStorage.removeItem(localStorage.key(i)!);
      }
    }
  }

  removePaxDataPricing() {
    for (let i = 0; i < localStorage.length; i++) {
      let key:any = localStorage.key(i);
      if (key.startsWith("pax-data-number-")) {
        localStorage.removeItem(key);
        i--;
      }
    }
  }

  removeTransportVertical(key: string) {
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i)?.includes(key)) {
        localStorage.removeItem(localStorage.key(i)!);
      }
    }
  }
  // Local storage
  getWithExpiry(key: string) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(decompress(itemStr));
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.data;
  }

  setWithExpiry(key: string, value: any, ttl: number) {
    const now = new Date();
    const item = {
      data: value,
      expiry: now.getTime() + ttl,
    };
    try {
      localStorage.setItem(key, compress(JSON.stringify(item)));
    } catch (e) {
      this.clear();
      localStorage.setItem(key, compress(JSON.stringify(item)));
    }
  }

  // Intervalo que limpia el local storage
  cleanInterval() {
    setInterval(
      () => {
        this.clean();
      },
      1000 * 60 * 5
    ); // Cada 5 minutos
  }
  // vaciar el localstorage
  clear() {
    // Las keys que no hay que borrar
    const exceptions = ['currentUser', 'token', 'refresh_token'];
    // en caso de busqueda omitimos los datos del formulario
    // Search slug
    const searchSlug = location.pathname.replace(
      environment.searchBaseUrl + '/',
      ''
    );
    if (searchSlug !== null) {
      exceptions.push(searchSlug);
    }
    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key) && !exceptions.includes(key)) {
        localStorage.removeItem(key);
      }
    }
  }
  // Expirar keys antiguas del localstorage
  clean() {
    const now = new Date();
    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        // Vamos a ver que tipo de entrada es
        // Por defecto la concederamos como String
        let item = localStorage[key];
        try {
          // Si es un json
          item = JSON.parse(localStorage[key]);
        } catch (e) {
          try {
            // Si es un json comprimido
            item = JSON.parse(decompress(localStorage[key]));
          } catch (e) {}
        }
        // Comprobamos si la entrada tiene fecha de caducidad
        try {
          if (item.expiry && now.getTime() > item.expiry) {
            localStorage.removeItem(key);
          }
        } catch (e) {}
      }
    }
  }

  saveWarningsToVoucher(booking: any): void {
    if (!booking) {
      return;
    }
    const warningSaved: any = {
      [booking.id]: [],
    };
    booking.lines[0]?.warnings?.forEach((warning) => {
      if (warning.id !== 'MODIFY_TTL_WARNING') {
        warningSaved[booking.id].push(warning);
      }
    });
    localStorage.setItem('warningSaved', JSON.stringify(warningSaved));
  }

  deleteWarningsToVoucher(bookingId: string): void {
    let warnings = JSON.parse(this.getItem('warningSaved')!) || {};
    if (Object.keys(warnings).length > 0) {
      warnings = Object.keys(warnings).filter(
        (warninglkey) => warninglkey !== bookingId
      );
    }
    localStorage.setItem('warningSaved', JSON.stringify(warnings));
  }

  saveLastCorporateSearch(search: any): void {
    const lastSearchs: any[] =
      JSON.parse(this.getItem('last-corporate-searchs')!) || [];
    if (
      lastSearchs.length > 0 &&
      lastSearchs.filter(
        (lastS) =>
          lastS.descName === search.descName &&
          lastS.codDepartment === search.codDepartment
      ).length === 0
    ) {
      if (lastSearchs.length === 4 || lastSearchs[0] === null) {
        lastSearchs.pop();
      }
      lastSearchs.unshift(search);
    } else if (lastSearchs.length === 0) {
      lastSearchs.unshift(search);
    }

    localStorage.setItem('last-corporate-searchs', JSON.stringify(lastSearchs));
  }

  gen(n) {
    return new Array(n * 1024 + 1).join('a');
  }

  setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  getItem(key: string) {
    return localStorage.getItem(key);
  }

  removeItem(key: string) {
    return localStorage.removeItem(key);
  }

  getKeyWithPartialKey(partialKey: string) {
    let key = Object.keys(localStorage).filter(
      (key) => key.includes(partialKey) && !key.includes('result')
    );
    return key.length > 0 ? key[0] : null;
  }

  utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }
}
