import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  AfterViewInit, forwardRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';
import { ErrorsService } from 'src/app/core/services/erros/errors.service';
import { AgencyService } from 'src/app/core/services/user/agency.service';
import { CorporateService } from 'src/app/core/services/user/corporate.service';
import { UserService } from 'src/app/core/services/user/user.service';
import {
  ManagementModalService,
  WarningModel,
} from 'src/app/core/services/utils/management-modal/management-modal.service';
import { NotificationsUtilService } from 'src/app/core/services/utils/notifications-util/notifications-util.service';
import { StorageService } from 'src/app/core/services/utils/storage.service';
import { CurrencyCustomPipe } from '../../../../../shared/pipes/currency-custom/currency-custom.pipe';
import { Ripple } from 'primeng/ripple';
import { FieldsCollectionComponent } from '../../../components/forms/fields/fields-collection.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'app-modal-options',
    templateUrl: './modal-options.component.html',
    styleUrls: ['./modal-options.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        SvgIconComponent,
        NgClass,
      forwardRef(() => FieldsCollectionComponent),
        ReactiveFormsModule,
        NgFor,
        Ripple,
        TranslateModule,
        CurrencyCustomPipe,
    ],
})
export class ModalOptionsComponent implements OnInit, AfterViewInit {
  buttonEmit = new EventEmitter<any>();
  @Input() warningAditional: WarningModel;
  @Input() title: string;
  @Input() description: string;
  @Input() icon: ModalIcon;
  @Input() buttons: ModalButtons[] = [];
  @Input() modalForm: FormGroup;
  @Input() inputs: any[] = [];
  @Input() showCrmInputs = false;
  @Input() crmStyles = false;
  @Input() selectRadioText = false;
  @Input() noFound = false;
  @Input() changePrice: ChangePriceData;
  @Input() changeRate: ChangeRateData;
  @Input() containTex: string;
  @Input() isCorporate = false;
  @Input() isAgencyModal = false;
  @Input() showClosedButton = false;
  @Input() modalPrintPadding = false;
  @Input() classId = '';
  @Input() voidFee: any;

  optional: any = {};
  residentCandidates: ModalButtons[] = [];
  activeAddCompany = false;
  companyError = false;
  iconRotateCorporate = false;

  modalInputsCrm: any[] = [
    {
      name: 'nameCrm',
      headerClass: 'name',
      options: {
        label: 'name_',
        placeholder: '',
        class: 'form-input',
      },
    },
    {
      name: 'surnamesCrm',
      headerClass: 'name',
      options: {
        label: 'surname_',
        placeholder: '',
        class: 'form-input',
      },
    },
    {
      name: 'emailCrm',
      headerClass: 'name',
      options: {
        label: 'email_',
        placeholder: '',
        class: 'form-input',
      },
    },
    {
      type: 'select-input',
      headerClass: 'inputPhone1 blockInput',
      options: {
        select: {
          label: 'telephone_',
          name: 'phonePrefix',
          options: {
            preselected: '+34',
            phone: true,
            class: 'phone',
            data: [],
          },
        },
        input: {
          name: 'phone',
          options: {
            class: 'form-input',
            label: '',
          },
        },
      },
    },
  ];

  buttonsOptions: any = {};
  crmData: any = {};
  showButtons = false;
  lastCorporateSearchs: string[];
  companySelected = { codAccounts: '', codDepartment: '' };
  newCompany: FormControl = new FormControl('', [Validators.required]);
  activeAddCompanyError = false;

  constructor(
    public translateService: TranslateService,
    private storageService: StorageService,
    private corporateService: CorporateService,
    private userService: UserService,
    private errorsService: ErrorsService,
    private managementModalService: ManagementModalService
  ) { }

  ngOnInit() {
    this.checkResidentsCandidates();
    this.initForm(this.inputs);
    if (this.storageService.getItem('last-corporate-searchs')) {
      this.lastCorporateSearchs = JSON.parse(
        this.storageService.getItem('last-corporate-searchs')!
      );
    }
    if (this.showCrmInputs) {
      this.addCrmForm();
      this.fillCrmForm();
    }
  }
  ngAfterViewInit() {
    if (
      this.userService.isVeci.value &&
      this.modalForm &&
      this.modalForm.get('name') &&
      this.modalForm.get('codDepartment')
    ) {
      if (
        !this.modalForm.get('codDepartment')?.value &&
        this.modalForm.get('name')?.value
      ) {
        this.modalForm
          .get('codDepartment')!
          .setValue(this.corporateService.getCodDepartment());
      }
      document.getElementById('input-codDepartament')!.style.display =
        this.modalForm.get('name')?.value &&
          this.corporateService.getCodDepartment()
          ? 'block'
          : 'none';
    }
  }

  toggleAddCompany(): void {
    this.activeAddCompany = !this.activeAddCompany;
    this.companyError = false;
    this.activeAddCompanyError = false;
  }

  openAddCompanyError(data?: any): void {
    if (!data || data.error?.error?.code !== 220325006) {
      this.activeAddCompany = false;
      this.activeAddCompanyError = !this.activeAddCompanyError;
    } else {
      const message = this.errorsService.treatmentsErrorDescription(data.error);
      this.emitInfo(message);

      if (data.error?.error?.code === 220325006) {
        this.corporateService.activeCorporate(false);
        this.managementModalService.deleteStackElement({
          id: 'corporate',
          isOpen: false,
        });
      }
    }
  }

  addCompany(company: string): void {
    this.corporateService
      .addCompany(company)
      .pipe(
        filter((res) => res.client),
        take(1)
      )
      .subscribe(
        (res) => {
          if (!res.error) {
            this.companyError = false;
            this.setCompany(res);
            this.toggleAddCompany();
          } else {
            this.companyError = true;
          }
        },
        (err) => {
          this.companyError = true;
        }
      );
  }

  checkResidentsCandidates() {
    this.buttons.forEach((button: ModalButtons) => {
      if (button.resident) {
        this.residentCandidates.push(button);
      }
    });
  }

  initForm(inputs: any[]) {
    if (inputs) {
      for (const control of inputs) {
        this.modalForm.addControl(control.name, new FormControl('', []));
        if (control.checked) {
          this.modalForm.get(control.name)?.setValue(control.val);
        }
        if (control.required) {
          this.modalForm.get(control.name)?.setValidators(Validators.required);
        }
        if (control.value) {
          this.modalForm.get(control.name)?.setValue(control.value);
        }
        if (control.disabled) {
          this.modalForm.get(control.name)?.disable();
        }
        if (control.name === 'email') {
          this.modalForm
            .get(control.name)!
            .setValidators([
              Validators.email,
              Validators.pattern(
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
              ),
              this.modalForm.get(control.name)?.validator!,
            ]);
        }
      }
    }
  }

  setCompany(company: any): void {
    if (
      company &&
      company.hasOwnProperty('descName') &&
      company.hasOwnProperty('codAccounts')
    ) {
      this.companySelected.codAccounts = company.codAccounts;
      this.modalForm.get('response')?.setValue(company);
      this.modalForm
        .get('name')!
        .setValue(`${company.codAccounts} - ${company.descName}`);
      if (this.modalForm.get('codDepartment')) {
        this.companySelected.codDepartment = company.codDepartment;

        if (document.getElementById('input-codDepartament')) {
          document.getElementById('input-codDepartament')!.style.display =
            'block';
        }

        this.modalForm.get('codDepartment')?.setValue(company.codDepartment);
      }
    }
  }

  addCrmForm() {
    this.modalForm.addControl(
      'nameCrm',
      new FormControl({ value: '', disabled: true }, [])
    );
    this.modalForm.addControl(
      'surnamesCrm',
      new FormControl({ value: '', disabled: true }, [])
    );
    this.modalForm.addControl('emailCrm', new FormControl('', []));
    this.modalForm.addControl('phonePrefix', new FormControl('', []));
    this.modalForm.addControl('phone', new FormControl('', []));
  }

  fillCrmForm() {
    if (this.crmData?.data && this.crmData.data[0]) {
      const data = this.crmData.data[0];
      this.modalForm.reset({
        nameCrm: data.name ?? '',
        surnamesCrm:
          data.firstSurname && data.secondSurname
            ? `${data.firstSurname} ${data.secondSurname}`
            : '',
        emailCrm: data.emails[0]?.email ?? '',
        phonePrefix: '+34',
        phone: data.phoneNumbers[0]?.number ?? '',
      });
    }
  }


  emitInfo(text: string) {
    this.buttonEmit.emit(text);
  }

  private reloadIconCorporate(): void {
    this.iconRotateCorporate = true;
    setTimeout(() => {
      this.iconRotateCorporate = false;
    }, 1000);
  }


}

export interface ModalIcon {
  src: string;
  class: string;
}

export interface ModalButtons {
  text: string;
  class: string;
  emitOnClick: string;
  resident?: boolean;
}

export interface ChangePriceData {
  originalRate: string;
  currentRate: string;
  originalAmount: number;
  currentAmount: number;
  currency: string;
}
export interface ChangeRateData {
  originalRate: string;
  currentRate: string;
  originalTotalRate: number;
  currentTotalRate: number;
  currency: string;
}
