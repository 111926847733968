import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { CurrencyPipe, registerLocaleData, TitleCasePipe } from '@angular/common';
import { provideHttpClient, HTTP_INTERCEPTORS, HttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { UserService } from './app/core/services/user/user.service';
import { CustomLoader } from './app/core/services/language/customLoader';
import { AppRoutingModule } from './app/app-routing.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpCancelService } from './app/core/services/httpCancel/httpcancel.service';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { HttpTokenInteceptor } from './app/core/interceptors/http.token.inteceptor';
import { IndexedDBStorageService } from './app/modules/storage/storages/indexed-dbstorage.service';
import { KEYVALUE_STORAGE_TOKEN } from './app/modules/storage/interfaces/storage.interface';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { GlobalConfig, ToastrModule } from 'ngx-toastr';
import { provideEnvironmentNgxMask, IConfig } from 'ngx-mask';
import { DynamicLocaleId, LocaleProvider } from './locale.provider';
import { TextTreatmentsPipe } from './app/shared/pipes/text-treatments/text-treatments.pipe';
import { NameProvidersFlightsPipe } from './app/shared/pipes/name-providers-flights/name-providers-flights.pipe';
import { CurrencyCustomPipe } from './app/shared/pipes/currency-custom/currency-custom.pipe';
import { DurationPipe } from './app/shared/pipes/duration.pipe';
import { TruncatePipe } from './app/shared/pipes/truncate/truncate.pipe';
import { FormatDatePipe } from './app/shared/pipes/formatDate.pipe';
import { FormatPatterTextPipe } from './app/shared/pipes/format-cuil-cuit.pipe';
// Locale imports
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt-PT';
import localeFr from '@angular/common/locales/fr';
import localeMX from '@angular/common/locales/es-MX';
import localeAR from '@angular/common/locales/es-AR';
import localeCL from '@angular/common/locales/es-CL';
import localePE from '@angular/common/locales/es-PE';
import localeCO from '@angular/common/locales/es-CO';
import { TokenizationPipe } from './app/shared/pipes/tokenization/tokenization.pipe';
import { CurrencySymbolPipe } from './app/shared/pipes/currency-symbol/currency-symbol.pipe';

// Register locale data
registerLocaleData(localeEn, 'en-en');
registerLocaleData(localeEs, 'es-es');
registerLocaleData(localePt, 'pt-pt');
registerLocaleData(localeFr, 'fr-fr');
registerLocaleData(localeMX, 'es-mx');
registerLocaleData(localeAR, 'es-ar');
registerLocaleData(localeCL, 'es-cl');
registerLocaleData(localePE, 'es-pe');
registerLocaleData(localeCO, 'es-co');

// Your existing configurations
const maskConfig: Partial<IConfig> = {
  validation: false,
};

const toastrConfig: Partial<GlobalConfig> = {
  maxOpened: 1,
  positionClass: 'toast-top-center',
  timeOut: 3000,
  enableHtml: true,
  tapToDismiss: false,
  autoDismiss: true,
};

if (environment.production) {
  enableProdMode();
}

// Bootstrap the application with all providers
bootstrapApplication(AppComponent, {
  providers: [
    TitleCasePipe,
    TextTreatmentsPipe,
    NameProvidersFlightsPipe,
    CurrencyCustomPipe,
    CurrencySymbolPipe,
    DurationPipe,
    TruncatePipe,
    FormatDatePipe,
    FormatPatterTextPipe,
    DatePipe,
    CurrencyPipe,
    TokenizationPipe,
    importProvidersFrom(
      AngularSvgIconModule.forRoot(),
      BrowserModule,
      AppRoutingModule,
      ToastrModule.forRoot(toastrConfig),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: CustomLoader,
          deps: [HttpClient, UserService],
        },
      })
    ),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInteceptor,
      multi: true,
    },
    provideEnvironmentNgxMask(maskConfig),
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: KEYVALUE_STORAGE_TOKEN, useClass: IndexedDBStorageService },
    JwtHelperService,
    {
      provide: LOCALE_ID,
      useClass: DynamicLocaleId,
      deps: [TranslateService],
    },
    LocaleProvider,
    HttpCancelService,
    provideAnimations(),
    // Add any other services that need to be provided globally
  ]
})
  .catch(err => console.error(err));
