import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import moment from 'moment';
import { AutoComplete, AutoCompleteModule } from 'primeng/autocomplete';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap, take, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AgencyService } from 'src/app/core/services/user/agency.service';
import { CorporateService } from 'src/app/core/services/user/corporate.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { NotificationsUtilService } from 'src/app/core/services/utils/notifications-util/notifications-util.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-generic-autocomplete',
    templateUrl: './generic-autocomplete.component.html',
    styleUrls: ['./generic-autocomplete.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        AutoCompleteModule,
        NgIf,
        TranslateModule,
    ],
})
export class GenericAutocompleteComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() options: any = {};
  @Output() openAddCompany = new EventEmitter<boolean>();
  @Output() openAddCompanyError = new EventEmitter<any>();

  results: any = [];
  selectedValue: any = {};
  allResults: any[] = [];
  noCompaniesFound = false;
  noAgencyFound = false;
  idAgency: any;
  inputValue: string;
  private destroy$: Subject<void>;

  constructor(
    private corporateService: CorporateService,
    private agencyService: AgencyService,
    private userService: UserService,
    private notificationsUtil: NotificationsUtilService,
    private authService: AuthenticationService
  ) {
    this.destroy$ = new Subject();
  }

  search(event) {
    this.options.showErrors = false;
    switch (this.options.suggestions) {
      case 'agency':
        this.searchAgency(event);
        break;
      case 'company':
        this.searchCompany(event);
        break;
      case 'surnameAutocompleteEmployeeCorporate':
        this.surnameEmployeeCorporate(event);
        break;
      case 'corporateEmployeeCorporate':
        this.employeeCorporateByLocator(event);
        break;

      default:
        this.results = [];
        break;
    }
  }

  surnameEmployeeCorporate(event) {
    this.corporateService
      .searchEmployeeCorporateBySurname(event.query)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.results = [];
        if (Array.isArray(res) && res.length > 0) {
          this.allResults = res;
          this.results = res.map((employee) => {
            return `${employee.surnames}, ${employee.names} | ${employee.locator}`;
          });
        }
      });
  }

  employeeCorporateByLocator(event) {
    if (event.query.length > 5) {
      this.corporateService
        .searchEmployeeCorporate(event.query)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.results = [];
            if (res.profile) {
              this.allResults.push(res.profile);
              this.results.push(
                `${res.profile.surnames}, ${res.profile.names} | ${res.profile.locator}`
              );
            }
          },
          (_) => {
            this.results = [];
          }
        );
    }
  }

  searchAgency(event) {
    this.inputValue = event.query;
    this.agencyService
      .getAgencies(event.query)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.results = [];
        if (Array.isArray(res) && res.length > 0) {
          this.noAgencyFound = false;
          this.allResults = res;
          this.results = res.map((agency) => agency.name);
        } else {
          this.noAgencyFound = this.userService.userIsCallCenter();
        }
      });
  }

  searchCompany(event) {
    if (this.parentForm.get('codDepartment')) {
      this.parentForm.get('codDepartment')?.setValue('');
    }
    this.corporateService
      .getCompanies(event.query)
      .pipe(
        tap(() => (this.results = [])),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        if (Array.isArray(res) && res.length > 0) {
          this.noCompaniesFound = false;
          this.allResults = res;
          this.results = res.map((company) => {
            return `${company.codAccounts} ${company.codDepartment} - ${company.descName}`;
          });
        } else {
          this.noCompaniesFound = true;
        }
      });
  }

  setSelectedValue(event) {
    let value;
    let dateUpdateInfo;
    const today = moment();
    if (this.options.suggestions === 'company') {
      const codAccount = event?.value?.split(' ')[0];
      value = this.allResults.find(
        (a) => a.codAccounts && a.codAccounts === codAccount
      );
      dateUpdateInfo = moment(value.updated_at);
      const valueInput = event?.value?.split('-');
      this.parentForm
        .get('name')
        ?.setValue(valueInput[0].split(' ')[0] + ' - ' + valueInput[1]);
      this.parentForm
        .get('company')
        ?.setValue(valueInput[0].split(' ')[0] + ' - ' + valueInput[1]);
      if (value.updated_at && today.diff(dateUpdateInfo, 'hours') > 24) {
        this.corporateService.addCompany(value.locator).subscribe({
          next: (res) => {
            value = res;
            this.parentForm.get('response')?.setValue(value);
          },
          error: (err) => {
            this.openAddCompanyModuleError(err);
          },
        });
      } else {
        this.parentForm.get('response')?.setValue(value);
      }
      if (this.userService.isVeci.value) {
        const selection = this.allResults.filter(
          (result) => result.codAccounts === codAccount
        )[0];
        if (this.parentForm.get('codDepartment')) {
          document.getElementById('input-codDepartament')!.style.display =
            'block';
          this.parentForm
            .get('codDepartment')
            ?.setValue(selection.codDepartment);
        }
      }
    }
    if (
      this.options.suggestions === 'surnameAutocompleteEmployeeCorporate' ||
      this.options.suggestions === 'corporateEmployeeCorporate'
    ) {
      const locator = event?.value?.split(' | ')[1];
      value = this.allResults.find((c) => c.locator === locator);
      dateUpdateInfo = moment(value.updated_at);
      if (value.updated_at && today.diff(dateUpdateInfo, 'hours') > 24) {
        this.corporateService
          .searchEmployeeCorporate(locator)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            if (res.profile) {
              value = res.profile;
              this.completeInfo(value);
            }
          });
      } else {
        this.completeInfo(value);
      }
    }
    if (
      this.options.suggestions !== 'surnameAutocompleteEmployeeCorporate' &&
      this.options.suggestions !== 'corporateEmployeeCorporate' &&
      this.options.suggestions !== 'company'
    ) {
      value = this.allResults.find((a) => a.name && a.name === event?.value);
      this.parentForm.get('response')?.setValue(value);
    }
  }
  completeInfo(value: any) {
    if (this.parentForm.get('name')) {
      this.corporateService.completeInfoCorporativeFormReservation(
        this.parentForm,
        value,
        this.options.company,
        this.options.op_company,
        this.options.candidateResident,
        this.options.docsOptions,
        this.options.mainForm
      );
    } else if (this.parentForm.parent?.get('name')) {
      this.corporateService.completeInfoCorporativeFormReservation(
        this.parentForm.parent,
        value,
        this.options.company,
        this.options.op_company,
        this.options.candidateResident,
        this.options.docsOptions,
        this.options.mainForm
      );
    }
  }

  openAddCompanyModule(): void {
    this.noCompaniesFound = false;
    this.openAddCompany.emit(true);
  }

  openAddAgencyModule(): void {
    this.noAgencyFound = false;
    this.openAddCompany.emit(true);
  }

  openAddCompanyModuleError(err): void {
    this.noCompaniesFound = false;
    this.openAddCompanyError.emit(err);
  }

  addAgencyPostSearchInput(): void {
    this.agencyService
      .createIfNotExistsAgen(
        this.inputValue,
        this.userService.getIATACallCenter()
      )
      .pipe(
        tap((res) => {
          if (!res.error) {
            this.idAgency = { id: res.i };
            this.parentForm.get('response')?.setValue(this.idAgency);
            this.notificationsUtil.infoSuccess('saveSuccess_');
          }
        }),
        filter((res) => !res.error),
        switchMap(() =>
          this.agencyService.setAgency(this.parentForm.get('response')?.value)
        ),
        switchMap(() => this.userService.getUserData()),
        take(1)
      )
      .subscribe((res) => {
        if (res && Object.keys(res).length > 0) {
          window.location.reload();
        } else {
          this.authService.logout();
        }
      });
  }

  suggestionsOnFocus(ac: AutoComplete, autoFocus: boolean = false) {
    setTimeout(() => {
      if (!ac.value && (ac.focused || autoFocus)) {
        ac.handleDropdownClick(ac);
      }
    }, 500);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
