import {
  Component,
  EventEmitter, forwardRef,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { ErrorsFieldsComponent } from '../errors-fields/errors-fields.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-switch-button',
    templateUrl: './switch-button.component.html',
    styleUrls: ['./switch-button.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        NgClass,
      forwardRef(() => ErrorsFieldsComponent),
        TooltipModule,
        TranslateModule,
    ],
})
export class SwitchButtonFormComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() options: any = {};
  @Input() item: switchElement;
  @Input() showSVGCheck: boolean = true;
  @Input() disabledSwitch: boolean = false;
  @Output() sendAction = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    //Control de boton disabled si navegamos entre tabs en caso de que el padre tenga este modulo

    if (changes['disabledSwitch']?.previousValue) {
      this.disabledSwitch = false;
    }
  }

  sendActionParent(item: switchElement) {
    if (this.item.hasOwnProperty('disabled')) {
      this.disabledSwitch = true;
    }
    this.sendAction.emit(item);
  }
}

export interface switchElement {
  id: number;
  value: boolean;
  item: any;
  section?: string;
  disabled?: boolean;
  tooltipText?: string;
  showTooltip?: boolean;
}
