import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { UserService } from '../../../core/services/user/user.service';

@Pipe({
    name: 'currencyCustom',
    standalone: true,
})
export class CurrencyCustomPipe implements PipeTransform {
  clientWantShowSymbol: string[] = [];
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private userService: UserService
  ) {}
  transform(
    value: number | string,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string
  ): any {
    if (value === undefined || value === null) return;
    let pipe = new CurrencyPipe(this.locale, currencyCode); // DEFAULT VALUE
    if (
      this.clientWantShowSymbol.length > 0 &&
      this.clientWantShowSymbol.includes(this.userService.getClientName())
    ) {
      switch (currencyCode?.toLocaleUpperCase()) {
        // Peso colombiano
        // Dírham marroquí
        case 'assets/images/icons/peso-colombiano.svg':
        case 'MAD':
          display = 'code';
          break;
        case 'COP':
          this.locale = 'en-en';
          break;
      }
    } else {
      display = 'code'; // a pincho para mostrar siempre ahora el code en vez del simbolo
    }

    let formattedValue = pipe.transform(
      value,
      currencyCode,
      display,
      digitsInfo,
      this.locale
    );
    return formattedValue?.replace(currencyCode || '', `${currencyCode} `);
  }
}
