import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ModalButtons,
  ModalIcon,
} from '../modal-options/modal-options.component';
import { baseUrlImgProvidersFavicon } from 'src/app/core/configurations/configurations';
import { FormatDatePipe } from '../../../../../shared/pipes/formatDate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { Ripple } from 'primeng/ripple';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'app-modal-errors-multiproviders',
    templateUrl: './modal-errors-multiproviders.component.html',
    styleUrls: ['./modal-errors-multiproviders.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        SvgIconComponent,
        NgFor,
        Ripple,
        TranslateModule,
        FormatDatePipe,
    ],
})
export class ModalErrorsMultiproviders {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() ListBookings: any[] = [];
  @Input() buttons: ModalButtons;
  @Input() icon: ModalIcon;
  @Output() closedEmit = new EventEmitter<any>();

  emitInfo(event) {
    this.closedEmit.emit(event);
  }
  protected readonly baseUrlImgProvidersFavicon = baseUrlImgProvidersFavicon;
}
