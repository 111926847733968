import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        TranslateModule,
    ],
})
export class LoadingComponent {
  @Input() options: any;
  @Input() faresLoading: any;
}
