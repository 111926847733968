import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CorporateService } from 'src/app/core/services/user/corporate.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { CrmService } from '../../../../../../core/services/crm-veci/crm.service';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorsFieldsComponent } from '../errors-fields/errors-fields.component';
import { OnlyNumber } from '../../../../../../shared/directive/onlynumber.directive';
import { NgClass, NgIf } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgxMaskDirective,
        NgClass,
        OnlyNumber,
        NgIf,
        forwardRef(() => ErrorsFieldsComponent),
        TranslateModule,
    ],
})
export class InputComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() type = 'text';
  @Input() options: any = {};
  @Input() currency: string;
  @Input() porcentOver: string
  @Output() controlValueInput = new EventEmitter<any>();
  @Output() cleanInvalidInput = new EventEmitter<any>();

  constructor(
    private crmService: CrmService,
    private corporateService: CorporateService,
    private userService: UserService
  ) {}

  click() {
    if (
      this.options.crm &&
      this.userService.isVeciAndWorkCRMExpediente.value &&
      !this.corporateService.isCorporate$.value
    ) {
      this.crmService.genModal();
    }
  }

  enforceNumericInput(value){
    this.controlValueInput.emit(value);
  }

  enforceCleanInvalidInput(value){
    this.cleanInvalidInput.emit(value);
  }
}
