// locale.provider.ts
import { LOCALE_ID, StaticClassProvider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export class DynamicLocaleId extends String {
  constructor(private service: TranslateService) {
    super();
  }

  override toString() {
    return this.service.currentLang;
  }
}

export const LocaleProvider: StaticClassProvider = {
  provide: LOCALE_ID,
  useClass: DynamicLocaleId,
  deps: [TranslateService],
};
