import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/services/user/user.service';
import { Ripple } from 'primeng/ripple';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgIf, NgClass, NgFor } from '@angular/common';
@Component({
    selector: 'app-modal-tutorial',
    templateUrl: './modal-tutorial.component.html',
    styleUrls: ['./modal-tutorial.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgFor,
        SvgIconComponent,
        Ripple,
        TranslateModule,
    ],
})
export class ModalTutorialComponent implements OnInit, OnDestroy {
  @Input() set options(e: any) {
    this.optional = e;
    this.init = e.init;
  }

  buttonEmit = new EventEmitter<any>();
  closedEmit = new EventEmitter<any>();
  init = false;
  optional: any = {};
  sliderPosition = 0;
  subscriptions: Subscription[] = [];
  textItemBookingList: string | null;

  texts = this.translate.instant('options-tour_');

  infoViewCont: any[] = [];
  objectInfoView = {};

  listIdsElements = [
    'search-booking-item-0',
    'search-booking-form',
    'last-search-title',
    'header',
    'bell',
    'budget',
  ];

  selectSlider: any;
  optionsGetListBooking = {
    page: {
      pageSize: 10,
      currentPage: 1,
    },
    filter: {
      listBooking: 'agency',
      orderBy: 'created_at',
      status: null,
      provider: null,
    },
  };

  optionsGetLastSearches = {
    page: {
      pageSize: 10,
      currentPage: 1,
    },
  };

  constructor(
    private translate: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.addAdmin();
    this.valuesInits();
  }

  addAdmin() {
    if (
      this.userService.userIsOperator() ||
      (this.userService.userIsAgency() && !this.userService.isVeci.value)
    ) {
      this.listIdsElements.push('admin');
    }
  }

  valuesInits() {
    const elementHtml = document?.getElementsByClassName(
      'cdk-overlay-pane'
    )[0] as HTMLElement;
    elementHtml.style.width = '690px';
    for (const text of this.texts) {
      if (this.listIdsElements.includes(Object.keys(text)[0])) {
        const index = this.listIdsElements.findIndex(
          (element) => element === Object.keys(text)[0]
        );
        this.objectInfoView = {
          id: index,
          description: text[Object.keys(text)[0]].description_,
          title: text[Object.keys(text)[0]].title_,
        };
        this.infoViewCont.push(this.objectInfoView);
      } else if (Object.keys(text)[0] === 'reset') {
        const index = this.listIdsElements.length;
        this.objectInfoView = {
          id: index,
          description: this.texts[this.texts.length - 1].reset.description_,
          title: this.texts[this.texts.length - 1].reset.title_,
          options: [
            {
              id: 'dowload',
              text: this.texts[this.texts.length - 1].reset.options_end_[0]
                .text_,
            },
            {
              id: 'video',
              text: this.texts[this.texts.length - 1].reset.options_end_[1]
                .text_,
            },
            {
              id: 'reboot',
              text: this.texts[this.texts.length - 1].reset.options_end_[2]
                .text_,
            },
          ],
        };
        this.infoViewCont.push(this.objectInfoView);
      }
    }
  }

  initTutorial() {
    const elementHtml = document?.getElementsByClassName(
      'cdk-overlay-pane'
    )[0] as HTMLElement;
    elementHtml.style.width = '380px';
    this.init = false;
    this.positionAndStylesModalByElement(this.sliderPosition);
    this.selectSlider = this.infoViewCont[this.sliderPosition];
    const butonsEffectHoverItemBookingList = document
      ?.getElementById(this.listIdsElements[this.sliderPosition])
      ?.getElementsByClassName('buttonsX')
      ?.item(0);
    if (butonsEffectHoverItemBookingList) {
      butonsEffectHoverItemBookingList.classList.add('buttons-effect-hover');
    }
  }

  reboot() {
    this.resetShowComponenetDestacado(this.sliderPosition - 1);
    this.sliderPosition = 0;
    this.positionAndStylesModalByElement(this.sliderPosition);
    this.selectSlider = this.infoViewCont[this.sliderPosition];
  }

  nextTutorialInstruction(id = null) {
    this.sliderPosition = !id ? this.sliderPosition + 1 : id;
    if (this.sliderPosition >= 0) {
      this.resetShowComponenetDestacado(this.sliderPosition - 1);
    }
    if (
      !document?.getElementById(
        this.listIdsElements[this.listIdsElements.length - 1]
      ) &&
      this.sliderPosition > this.listIdsElements.length - 1
    ) {
      this.sliderPosition = this.listIdsElements.length - 1;
    }
    this.positionAndStylesModalByElement(this.sliderPosition);
    this.selectSlider = this.infoViewCont[this.sliderPosition];
  }

  beforeTutorialInstruction(id = null) {
    this.sliderPosition = id ?? this.sliderPosition - 1;
    if (this.sliderPosition >= 0) {
      this.resetShowComponenetDestacado(this.sliderPosition + 1);
    }
    this.positionAndStylesModalByElement(this.sliderPosition);
    this.selectSlider = this.infoViewCont[this.sliderPosition];
  }

  positionAndStylesModalByElement(index) {
    const elementHtml = document?.getElementById(this.listIdsElements[index]);
    if (index !== this.listIdsElements.length && elementHtml) {
      const elementHtmlModal = document?.getElementsByClassName(
        'cdk-overlay-pane'
      )[0] as HTMLElement;
      elementHtmlModal.style.width = '380px';
      elementHtml.style.zIndex = '99999';
      elementHtml.style.backgroundColor = '#FFF';
      elementHtml.style.position = 'relative';
      if (
        window.innerWidth < 992 &&
        (this.listIdsElements[index] === 'search-booking-form' ||
          this.listIdsElements[index] === 'last-search-title' ||
          this.listIdsElements[index] === 'header')
      ) {
        window.scrollTo({
          top: elementHtml.getBoundingClientRect().top - 70,
          left: 0,
        });
      }
      const elementHlighted = elementHtml.getBoundingClientRect();

      var boxContainModal = document?.getElementsByClassName(
        'cdk-global-overlay-wrapper'
      ) as HTMLCollectionOf<HTMLElement>;
      boxContainModal[boxContainModal.length - 1].style.display =
        index === this.listIdsElements.length
          ? (null as unknown as string)
          : 'inherit';
      boxContainModal[boxContainModal.length - 1].style.left =
        index === 2 && window.innerWidth > 991
          ? elementHlighted.left - 70 + 'px'
          : elementHlighted.left + 'px';
      boxContainModal[boxContainModal.length - 1].style.top =
        elementHlighted.top + elementHlighted.height + 'px';
      boxContainModal[boxContainModal.length - 1].style.marginTop = '20px';
      elementHtml.style.borderRadius = '5px';
      elementHtml.style.pointerEvents = 'none';

      if (
        this.listIdsElements[index] === 'search-booking-item-0' &&
        elementHtml?.getElementsByClassName('date-reservation')
      ) {
        if (
          elementHtml &&
          elementHtml.firstChild &&
          elementHtml.firstChild.firstChild
        ) {
          this.textItemBookingList =
            elementHtml.firstChild.firstChild.childNodes.item(0).nodeValue;
          elementHtml
            ?.getElementsByClassName('date-reservation')
            ?.item(0)!
            .classList.add('green-text-projection');
        }
        if (elementHtml.firstChild?.firstChild?.firstChild) {
          elementHtml.firstChild.firstChild.firstChild.nodeValue =
            this.translate.instant('booking_example_');
          const butonsEffectHoverItemBookingList = elementHtml
            ?.getElementsByClassName('buttonsX')
            ?.item(0);
          if (butonsEffectHoverItemBookingList) {
            butonsEffectHoverItemBookingList.classList.add(
              'buttons-effect-hover'
            );
          }
        }

        if (elementHtml?.getElementsByClassName('date-reservation')) {
          elementHtml
            .getElementsByClassName('date-reservation')
            .item(0)!
            .closest('div')!.style.backgroundColor = '#FFF';
          elementHtml
            .getElementsByClassName('date-reservation')
            .item(0)!
            .closest('div')!.style.width = '100%';
          elementHtml
            .getElementsByClassName('date-reservation')
            .item(0)!
            .closest('div')!.style.textAlign = 'center';
          elementHtml
            .getElementsByClassName('date-reservation')
            .item(0)!
            .closest('div')!.style.borderRadius = '5PX';
        }
      }

      if (this.listIdsElements[index] === 'search-booking-form') {
        boxContainModal[boxContainModal.length - 1].style.marginTop = '60px';
        elementHtml.style.padding = '20px 20px 5px 20px';
        elementHtml.style.marginBottom = '-16px';
        elementHtml.style.top = '-21px';
      }

      if (
        this.listIdsElements[index] === 'bell' ||
        this.listIdsElements[index] === 'budget' ||
        this.listIdsElements[index] === 'admin'
      ) {
        elementHtml.style.padding = null as unknown as string;
        const ulElement = document?.getElementsByClassName(
          'fix-width'
        ) as HTMLCollectionOf<HTMLElement>;
        ulElement[0].style.width = '100%';
        if (document?.getElementById('navbar')) {
          document.getElementById('navbar')!.style.zIndex = 'auto';
        }

        boxContainModal[boxContainModal.length - 1].style.top =
          elementHlighted.top - 20 + 'px';
        boxContainModal[boxContainModal.length - 1].style.left =
          elementHlighted.left + 50 + 'px';
      }
    } else {
      const elementHtml = document?.getElementsByClassName(
        'cdk-overlay-pane'
      )[0] as HTMLElement;
      elementHtml.style.width = '580px';
      var boxContainModal = document?.getElementsByClassName(
        'cdk-global-overlay-wrapper'
      ) as HTMLCollectionOf<HTMLElement>;
      boxContainModal[boxContainModal.length - 1].style.justifyContent =
        'center';
      boxContainModal[boxContainModal.length - 1].style.alignItems = 'center';
      boxContainModal[boxContainModal.length - 1].style.left = '0';
      boxContainModal[boxContainModal.length - 1].style.top = '0';
      boxContainModal[boxContainModal.length - 1].style.display = 'flex';
    }
  }

  resetShowComponenetDestacado(index) {
    const elementHtml = document?.getElementById(this.listIdsElements[index]);
    if (elementHtml) {
      elementHtml.style.zIndex = null as unknown as string;
      elementHtml.style.backgroundColor = null as unknown as string;
      elementHtml.style.position = null as unknown as string;
      elementHtml.style.borderRadius = null as unknown as string;
    }

    const ulElement = document?.getElementsByClassName(
      'fix-width'
    ) as HTMLCollectionOf<HTMLElement>;
    ulElement[0].style.width = null as unknown as string;
    this.resetStyleException(elementHtml, index);
  }

  closedTutorialAndCleanStyles() {
    const lastItem = this.listIdsElements[this.listIdsElements.length - 1];
    this.listIdsElements.forEach((element, index) => {
      if (
        element !== lastItem ||
        (document?.getElementById(lastItem) && element === lastItem)
      ) {
        const elementHtml = document?.getElementById(element);

        if (elementHtml) {
          elementHtml.style.zIndex = null as unknown as string;
          elementHtml.style.backgroundColor = null as unknown as string;
          elementHtml.style.position = null as unknown as string;
          elementHtml.style.borderRadius = null as unknown as string;
          elementHtml.style.pointerEvents = null as unknown as string;
        }

        const ulElement = document?.getElementsByClassName(
          'fix-width'
        ) as HTMLCollectionOf<HTMLElement>;

        if (ulElement) {
          ulElement[0].style.width = null as unknown as string;
        }

        this.resetStyleException(elementHtml, index);
      }
      this.closedEmit.emit('closed');
    });
  }

  resetStyleException(elementHtml, index) {
    if (this.listIdsElements[index] === 'search-booking-item-0') {
      if (elementHtml?.firstChild?.firstChild?.firstChild) {
        elementHtml.firstChild.firstChild.firstChild.nodeValue =
          this.textItemBookingList;
      }
      elementHtml
        ?.getElementsByClassName('date-reservation')
        .item(0)
        .classList.remove('green-text-projection');
      const butonsEffectHoverItemBookingList = elementHtml
        ?.getElementsByClassName('buttonsX')
        .item(0);
      butonsEffectHoverItemBookingList?.classList.remove('buttons-effect-hover');
    }

    if (this.listIdsElements[index] === 'search-booking-form' && elementHtml) {
      elementHtml.style.padding = null;
      elementHtml.style.marginBottom = null;
      elementHtml.style.top = null;
    }
    if (
      this.listIdsElements[index] === 'bell' ||
      this.listIdsElements[index] === 'budget' ||
      this.listIdsElements[index] === 'admin'
    ) {
      elementHtml.style.padding = null;
      if (document?.getElementById('navbar')) {
        document.getElementById('navbar')!.style.zIndex =
          null as unknown as string;
        document.getElementById('navbar')!.style.position =
          null as unknown as string;
      }
    }
  }

  action(action: string) {
    switch (action) {
      case 'dowload':
        // open pdf
        break;
      case 'video':
        // enlace video
        break;
      case 'reboot':
        // reiniciar tour
        this.reboot();
        break;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
