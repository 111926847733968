import { Component, Input, OnInit, Output, EventEmitter, forwardRef } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorsFieldsComponent } from '../errors-fields/errors-fields.component';
import { TooltipModule } from 'primeng/tooltip';
import { SvgIconComponent } from 'angular-svg-icon';
import { PrimeTemplate } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgClass,
        NgIf,
        DropdownModule,
        PrimeTemplate,
        SvgIconComponent,
        TooltipModule,
      forwardRef(() => ErrorsFieldsComponent),
        TranslateModule,
    ],
})
export class SelectComponent implements OnInit {
  @Input() parentForm!: FormGroup;
  @Input() data: any[] = [];
  @Input() fCName: string = '';
  @Input() options: any = {};
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  active = false;

  selectStylePlaceholder: string[] = ['select_', 'country_', 'time_zone_'];

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    if (this.data?.length === 1 && this.options?.oneSelection) {
      this.parentForm.get(this.fCName)?.setValue(this.data[0]?.value);
    }
    this.sortData()
   }

   sortData() {
    if (this.options?.phone ) {
      this.data?.sort((a, b) =>
        a.name[this.languageService.getLocaleCode()]
          .localeCompare(b.name[this.languageService.getLocaleCode()]));
    }
   }

  show() {
    this.active = true;
  }

  hide() {
    this.active = false;
  }

  getItem(item: any, options: any) {
    return options.phone
      ? item.name[this.languageService.getLocaleCode()] + ' +' + item.value
      : item.label;
  }
}
