import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import * as momenttimezone from 'moment-timezone';
import { dateFormat } from 'src/app/core/configurations/configurations';
import { LanguageService } from 'src/app/core/services/language/language.service';

@Pipe({
    name: 'formatDate',
    standalone: true,
})
export class FormatDatePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private languageService: LanguageService
  ) {}

  transform(
    value: string | Date,
    format?: string,
    specialFormat = null,
    timeZone = null
  ): string {
    let date: any;
    if (typeof value === 'string') {
      date = new Date(value);
    } else {
      date = value;
    }
    if (timeZone) {
      const time = momenttimezone.tz(value, timeZone);
      const localtz = momenttimezone.tz.guess();
      date = time.clone().tz(localtz).format();
    }
    let response: any = '';
    if (format === 'inputCalendarMoment') {
      response = moment(date, 'D_M_YYYY')
        .locale(this.languageService.getLocaleCode())
        .format(
          this.getDateFormatByLanguage(
            this.languageService.getLocaleCode(),
            format
          )
        );
    } else if (date && date !== 'Invalid date' && format) {
      response = this.datePipe.transform(
        date,
        this.getDateFormatByLanguage(
          this.languageService.getLocaleCode(),
          format
        )
      );
    } else {
      response = null;
    }

    if (response && response.includes('.') && format !== 'dateWithDotted') {
      response = response.replace('.', ' ');
    }
    if (specialFormat === 'all') {
      const defaultResponse =
        '<strong class="day">' +
        response.split(' ')[0] +
        '</strong> ' +
        response.split(' ')[1] +
        '<br>' +
        response.split(',')[1];
      const languageResponses = {
        es: defaultResponse,
        fr: defaultResponse.replace(',,', ','),
        pt: defaultResponse,
        en:
          '<strong class="day">' +
          response.split(' ')[2] +
          '</strong> ' +
          response.split(' ')[1] +
          ',<br>' +
          response.split(' ')[0],
      };
      response = languageResponses[this.languageService.getLocaleCode()];
    } else if (specialFormat === 'partial') {
      response =
        '<strong class="day">' +
        response.split(' ')[0] +
        '</strong> ' +
        response.split(' ')[1] +
        ',' +
        response.split(',')[1];
    }
    return response;
  }

  getDateFormatByLanguage(local: string, format: string): string {
    let fomatResponse = 'medium';
    if (dateFormat[local] && dateFormat[local][format]) {
      fomatResponse = dateFormat[local][format];
    } else if (dateFormat.default[format]) {
      fomatResponse = dateFormat.default[format];
    }
    return fomatResponse;
  }
}
