import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-input-icon',
    templateUrl: './input-icon.component.html',
    styleUrls: ['./input-icon.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        SvgIconComponent,
        TranslateModule,
    ],
})
export class InputIconComponent {
  @Input() options: any;
  @Input() fCName: string;
  @Input() parentForm: FormGroup;
}
