import { Component } from '@angular/core';

@Component({
    selector: 'app-loading-screen',
    templateUrl: './loading-screen.component.html',
    styleUrls: ['./loading-screen.component.scss'],
    standalone: true
})
export class LoadingScreenComponent {


}
