import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace',
    standalone: true
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, strToReplace: string, replacementStr?: string, replaceAll = false): string {
    let change = value;
    let originalStr = replaceAll ? new RegExp(strToReplace, 'g') : strToReplace;
    let replaceStr = replacementStr ?? '';
    if (value && value.includes(strToReplace)) {
      change = value.replace(originalStr, replaceStr);
    }
    return change;
  }
}
