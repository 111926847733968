import { StorageService } from '../utils/storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TokenStatusService {
  constructor(
    private storageService: StorageService,
    private jwtHelper: JwtHelperService
  ) {}

  decodeToken(token) {
    if (!token || token === '') {
      return '';
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  }
  getItem(key: string) {
    return localStorage.getItem(key);
  }
  setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  removeItem(key: string) {
    return localStorage.removeItem(key);
  }

  removePaxDataPricing() {
    for (let i = 0; i < localStorage.length; i++) {
      let key:any = localStorage.key(i);
      if (key.startsWith("pax-data-number-")) {
        localStorage.removeItem(key);
        i--;
      }
    }
  }

  removeTransportVertical(key: string) {
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i)?.includes(key)) {
        localStorage.removeItem(localStorage.key(i)!);
      }
    }
  }

  utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }
}
