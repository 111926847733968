import {
  Component,
  OnInit,
  HostListener,
  Input,
  Renderer2,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { NgIf, NgClass } from '@angular/common';

/**
 * Dropdown component
 */
@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass],
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() ref: Element;
  @Input() options: any;
  @Input() disabled: any;
  @Input() set waiting(e) {
    if (!this.disabled) {
      this.wait = e;
      this.show = false;
      this.emitter.emit(false);
      this.ref.classList.remove('open');

      if (!this.wait) {
        this.show = true;
        this.emitter.emit(true);
      }
    }
  }
  @Output('showChange') emitter: EventEmitter<any> = new EventEmitter<any>();

  private wasInside = false;
  @Input() show = false;
  private wait: boolean;
  focus = false;
  shift = false;

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('window:keydown', ['$event'])
  keyDown(event) {
    if (this.shift && event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      this.focus = false;
      this.show = false;
      this.ref.classList.remove('open');
      return;
    }
    if (event.key === 'Shift') {
      this.shift = true;
    }
    if (event.key === 'Tab' && this.show) {
      this.focus = false;
      this.show = false;
      this.ref.classList.remove('open');
      this.emitter.emit(false);
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyUp(event: KeyboardEvent) {
    if (event.key === 'Shift') {
      this.shift = false;
    }
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside && this.show) {
      this.show = false;
      this.ref.classList.remove('open');
      this.emitter.emit(false);
    }
    this.wasInside = false;
    this.focus = false;
  }

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    if (this.ref) {
      this.renderer.listen(this.ref, 'click', () => {
        if (!this.disabled && this.ref.tagName !== 'INPUT' && !this.focus) {
          this.show = !this.show;
          if (this.show) {
            this.ref.classList.add('open');
            this.emitter.emit(true);
          } else {
            this.ref.classList.remove('open');
            this.emitter.emit(false);
          }
          this.wasInside = true;
        }
      });
      this.renderer.listen(this.ref, 'focus', () => {
        if (!this.show && !this.disabled && !this.wait) {
          this.focus = true;
          this.show = true;
          this.ref.classList.add('open');
          this.emitter.emit(true);
          this.wasInside = true;
          setTimeout(() => {
            this.focus = false;
          }, 300);
        }
      });
      this.renderer.listen(this.ref, 'blur', () => {
        this.wasInside = false;
      });
    }
  }

  ngOnChanges() {
    if (!this.show && this.ref.classList.contains('open')) {
      this.ref.classList.remove('open');
    }
  }
}
