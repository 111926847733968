import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { Ripple } from 'primeng/ripple';

@Component({
    selector: 'app-submit',
    templateUrl: './submit.component.html',
    styleUrls: ['./submit.component.scss'],
    standalone: true,
    imports: [Ripple, NgClass, TranslateModule]
})
export class SubmitComponent {

  @Input() literal: string;
  @Input() options: any = {};

}
