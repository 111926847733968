import { Injectable, EventEmitter, Inject, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../configurations/configurations';
import { StorageService } from '../utils/storage.service';

/**
 * Service load the app's language
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  langs: any[] = [];
  public icons = {
    es: 'spain',
    en: 'united-kingdom',
    pt: 'portugal',
    fr: 'france',
    ar: 'argentina',
    cl: 'chile',
    co: 'colombia',
    mx: 'mexico',
    pe: 'peru',
  };

  public changeLanguageEvent: EventEmitter<any> = new EventEmitter();

  defaultLang = 'es-es';

  // Dynamic translations depending on loaded modules
  public lazyLoadedModules: string[] = [];

  constructor(
    private storageService: StorageService,
    public translate: TranslateService,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  /**
   * Init load the languages
   */
  initialize(userDefauldLanguage: any): void {
    this.langs = languages;
    this.translate.addLangs(this.getAllLanguagesValues());
    this.setDefaultLang(this.getDefaultLanguage(userDefauldLanguage));
  }

  getAllLanguagesValues(): string[] {
    this.langs = this.sortLanguages();
    const langs: any[] = this.langs.map((val) => {
      return val.value;
    });
    return langs;
  }

  private sortLanguages(): any[] {
    return this.langs.sort((a, b) => {
      return a.label < b.label ? -1 : 1;
    });
  }

  getLocaleCode(): string {
    return this.locale.split('-')[0];
  }

  getLocale(): string {
    return this.storageService.getItem('lang') ?? this.locale;
  }

  setDefaultLang(lang: string): void {
    this.translate.setDefaultLang(lang);
    let truncateLocalStorage = localStorage.getItem('lang');
    if (
      localStorage.getItem('lang') &&
      localStorage.getItem('lang')?.length === 2
    ) {
      truncateLocalStorage = this.langs.find((l: any) =>
        l.value.includes(localStorage.getItem('lang'))
      ).value;
    }
    this.translate.use(truncateLocalStorage ?? this.translate.getDefaultLang());
    this.storageService.setItem('lang', truncateLocalStorage ?? lang);
  }

  /**
   * Get default language, by default get the browser lang
   * or get the first in the array
   */
  getDefaultLanguage(userdefauldValue): string {
    return (
      this.langs.find((l) => l.value === userdefauldValue) ||
      this.langs.find((l) => l.language === this.translate.getBrowserLang()) ||
      this.langs.find((l) => l.value === this.defaultLang)
    )?.value;
  }

  /**
   * Load a single translation module
   * @param moduleName the name of the module
   */
  loadModuleTranslations(moduleName: string): void {
    if (!this.lazyLoadedModules.includes(moduleName)) {
      this.lazyLoadedModules.push(moduleName);
    }

    this.translate.currentLoader
      .getTranslation(
        moduleName + '/' + moduleName + '-' + this.translate.currentLang
      )
      .subscribe((newTranslations) => {
        this.translate.setTranslation(
          this.translate.currentLang,
          newTranslations,
          true
        );
      });
  }

  /**
   * Change the language of applicatoin
   * and save it in localstorage
   * @param language language to change 'es' | 'en' | 'pt'
   */
  changeLanguage(language: string): void {
    if (
      !this.storageService.getItem('lang') ||
      this.translate.currentLang !== language
    ) {
      this.storageService.setItem('lang', language);
    }
    if (this.translate.currentLang !== language) {
      this.translate.use(language).subscribe((res) => {
        this.reloadModuleTranslation();
        this.changeLanguageEvent.emit(true);
        window.location.reload();
      });
    }
  }
  reloadModuleTranslation() {
    this.lazyLoadedModules.forEach((moduleName) => {
      this.loadModuleTranslations(moduleName);
    });
  }
}
