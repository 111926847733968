import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-input-radio',
    templateUrl: './input-radio.component.html',
    styleUrls: ['./input-radio.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgClass,
        NgIf,
        TranslateModule,
    ],
})
export class InputRadioComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() type = 'radio';
  @Input() name: string;
  @Input() id: string;
  @Input() value: string;
  @Input() options: any = {};
  @Input() checked: any;

  ngOnInit(): void {
    if (this.checked) {
      this.fireChange();
    }
  }

  fireChange(): void {
    this.parentForm
      .get(this.fCName)
      ?.setValue(this.value ? this.value : this.name, {
        emitEvent: this.options.emit ? this.options.emit : false,
      });
  }
}
