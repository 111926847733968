import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TruncatePipe } from '../../../../../../shared/pipes/truncate/truncate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TooltipModule } from 'primeng/tooltip';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-input-check-special',
    templateUrl: './input-check-special.component.html',
    styleUrls: ['./input-check-special.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgClass,
        NgIf,
        TooltipModule,
        MatTooltip,
        SvgIconComponent,
        TranslateModule,
        TruncatePipe,
    ],
})
export class InputCheckSpecialComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() name: string;
  @Input() value: string;
  @Input() options: any = {};
  @Input() inputs: any;

  onChanges($event, value) {
    for (const input of this.inputs) {
      if (input.name === this.fCName) {
        this.parentForm
          .get(this.fCName)
          ?.setValue($event.target.checked ? value : null, {
            emitEvent: this.options.emit ? this.options.emit : false,
          });
      } else {
        this.parentForm.get(input.name)?.setValue(null, {
          emitEvent: this.options.emit ? this.options.emit : false,
        });
      }
    }
  }
}
