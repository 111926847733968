import { Component, EventEmitter, Input } from '@angular/core';
import { CarouselComponent } from '../../../components/carousel/carousel.component';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
    selector: 'app-modal-carousel',
    templateUrl: './modal-carousel.component.html',
    styleUrls: ['./modal-carousel.component.scss'],
    standalone: true,
    imports: [SvgIconComponent, CarouselComponent],
})
export class ModalCarouselComponent {
  @Input() listImgCarosuel: string[] = [];
  closedEmit = new EventEmitter<any>();

  closedNews() {
    this.closedEmit.emit('closed');
  }
}
