import { Component, forwardRef, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorsFieldsComponent } from '../errors-fields/errors-fields.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgClass,
        forwardRef(() => ErrorsFieldsComponent),
        TranslateModule,
    ],
})
export class TextareaComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() options: any = {};
}
