import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationEventService {
  private logoutSubject = new Subject<{tn?: string}>();
  logout$ = this.logoutSubject.asObservable();

  triggerLogout(tn?: string): void {
    this.logoutSubject.next({ tn });
  }
}
