import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
    selector: 'app-chip-multiselect',
    templateUrl: './chip-multiselect.component.html',
    styleUrls: ['./chip-multiselect.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, MultiSelectModule],
})
export class ChipMultiselectComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() options: any = {};

}
