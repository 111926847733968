import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Ripple } from 'primeng/ripple';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
    selector: 'app-not-found-template',
    templateUrl: './not-found-template.component.html',
    styleUrls: ['./not-found-template.component.scss'],
    standalone: true,
    imports: [
        SvgIconComponent,
        Ripple,
        TranslateModule,
    ],
})
export class NotFoundTemplateComponent {
  constructor(private router: Router) {}

  goBack() {
    this.router.navigate(['/']);
  }
}
