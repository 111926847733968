import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import countries from 'src/assets/json/countries.json';
import { TranslateModule } from '@ngx-translate/core';
import { InputComponent } from '../input/input.component';
import { SelectComponent } from '../select/select.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        SelectComponent,
        InputComponent,
        TranslateModule,
    ],
})
export class SelectInputComponent implements OnInit {
  @Input() inputs: any = {};
  @Input() parentForm: FormGroup;

  ngOnInit() {
    this.inputs.select.options.placeholder = 'prefix_';
    this.inputs.select.options.data = countries.countries;
    if (this.inputs.select.options.preselected) {
      let prefix: string;
      if (this.inputs.select.options.preselected.includes('+')) {
        prefix = this.inputs.select.options.preselected.split('+')[1];
      } else {
        prefix = this.inputs.select.options.preselected;
      }
      this.inputs.select.options.data.map((c) => {
        if (prefix === c.value) {
          this.inputs.select.options.placeholder = c.label + ' +' + c.value;
        }
      });
    }
  }
}
