import {
  Component,
  Input,
  Output,
  HostListener,
  EventEmitter, forwardRef,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ReplacePipe } from '../../../../../shared/pipes/replace/replace.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { SpecialInputWithCheckComponent } from './special-input-with-check/special-input-with-check.component';
import { CounterComponent } from './counter/counter.component';
import { InputMaskComponent } from './input-mask/input-mask.component';
import { SwitchButtonFormComponent } from './switch-button/switch-button.component';
import { TextareaComponent } from './textarea/textarea.component';
import { InputIconComponent } from './input-icon/input-icon.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { MultipleInputComponent } from './multiple-input/multiple-input.component';
import { ChipMultiselectComponent } from './chip-multiselect/chip-multiselect.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { InputAutocompleteNgprimeComponent } from './input-autocomplete-ngprime/input-autocomplete-ngprime.component';
import { GenericAutocompleteComponent } from './generic-autocomplete/generic-autocomplete.component';
import { TextOnlyReadComponent } from './text-only-read/text-only-read.component';
import { InputCheckSpecialComponent } from './input-check-special/input-check-special.component';
import { InputRadioComponent } from './input-radio/input-radio.component';
import { SliderComponent } from './slider/slider.component';
import { SelectComponent } from './select/select.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { InputIataComponent } from './input-iata/input-iata.component';
import { InputComponent } from './input/input.component';
import { TooltipModule } from 'primeng/tooltip';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgFor, NgIf, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, SlicePipe } from '@angular/common';
import { InputCalendarTimeComponent } from './input-calendar-time/input-calendar-time.component';

@Component({
    selector: 'app-fields-collection',
    templateUrl: './fields-collection.component.html',
    styleUrls: ['./fields-collection.component.scss'],
    standalone: true,
    imports: [
        forwardRef(() => InputCalendarTimeComponent),
        NgFor,
        NgIf,
        NgClass,
        NgSwitch,
        SvgIconComponent,
        TooltipModule,
        NgSwitchCase,
      forwardRef(() => InputComponent),
      forwardRef(() => InputIataComponent),
      forwardRef(() => CheckboxComponent),
      forwardRef(() => SelectComponent),
      forwardRef(() => SliderComponent),
      forwardRef(() => InputRadioComponent),
      forwardRef(() => InputCheckSpecialComponent),
      forwardRef(() => TextOnlyReadComponent),
      forwardRef(() => GenericAutocompleteComponent),
      forwardRef(() => InputAutocompleteNgprimeComponent),
      forwardRef(() => MultiselectComponent),
      forwardRef(() => ChipMultiselectComponent),
      forwardRef(() => MultipleInputComponent),
      forwardRef(() => SelectInputComponent),
      forwardRef(() => InputIconComponent),
      forwardRef(() => TextareaComponent),
      forwardRef(() => SwitchButtonFormComponent),
      forwardRef(() => InputMaskComponent),
      forwardRef(() => CounterComponent),
      forwardRef(() => SpecialInputWithCheckComponent),
        CounterComponent,
        ReactiveFormsModule,
        SpecialInputWithCheckComponent,
        NgSwitchDefault,
        TranslateModule,
        SlicePipe,
        ReplacePipe,
    ],
})
export class FieldsCollectionComponent {
  @Input() inputs: any[];
  @Input() form: FormGroup;
  @Input() options: any = {};
  @Input() groupName: any = {};
  @Input() showButtonOnly = false;
  @Input() numberOfMonths: any = 2;
  @Input() inputsName: string;
  @Output() return = new EventEmitter<any>();
  @Output() onlyButton = new EventEmitter<any>();
  @Output() openAddCompany = new EventEmitter<boolean>();
  @Output() openAddCompanyError = new EventEmitter<any>();
  @Output() controlValueInput = new EventEmitter<any>();
  @Output() cleanInvalidInput = new EventEmitter<any>();

  calendarOptions: any = {
    initialDay: new Date(),
    class: 'inputOnSearchBar white',
  };
  fieldTextType = false;
  src = {
    password: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },

    confirmPassword: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },
    data_password: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },
    data_agtpwd: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },
    data_SecurityPassword: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },

    data_logonPassword: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },
    password_confirm: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },
  };

  // Numero de calendarios segun tamaño de pantalla
  public phoneWidth: any = environment.phoneWidth;

  @HostListener('window:resize', ['$event'])
  // TODO: lanzar método en oninit
  onResize(event) {
    if (event.target.innerWidth > this.phoneWidth) {
      this.numberOfMonths = 2;
    } else {
      this.numberOfMonths = 1;
    }
  }

  toggleFieldTextType(name) {
    this.src[name].fieldText = !this.src[name].fieldText;

    if (!this.src[name].fieldText) {
      this.src[name].src = '/assets/images/icons/closedeye.svg';
    } else {
      this.src[name].src = '/assets/images/icons/openeye.svg';
    }
  }

  butttonOnly(name: string) {
    this.onlyButton.emit(name);
  }

  openAddCompanyModule(): void {
    this.openAddCompany.emit(true);
  }
  openAddCompanyErrorSend(data: any): void {
    this.openAddCompanyError.emit(data);
  }

  enforceNumericInputField(event): void{
    this.controlValueInput.emit(event)
  }

  cleanInvalidInputField(event): void{
    this.controlValueInput.emit(event)
  }

  checkIfFormHaveControls(form: any) {
    let check = false;

    if (form?.value !== null && typeof form?.value === 'object') {
      check = true;
    }

    return check;
  }
}
