import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import { UserService } from '../../../core/services/user/user.service';

@Pipe({
    name: 'currencySymbol',
    standalone: true,
})
export class CurrencySymbolPipe implements PipeTransform {
  constructor(private userService: UserService) {}
  clientWantShowSymbol: string[] = [];
  transform(
    code: string,
    format: 'wide' | 'narrow' = 'narrow',
    locale?: string
  ): any {
    if (
      this.clientWantShowSymbol.length > 0 &&
      this.clientWantShowSymbol.includes(this.userService.getClientName())
    ) {
      // aqui poner el user current para validar usuario que puedan o no mostrar los symbols
      switch (code?.toLocaleUpperCase()) {
        // Peso colombiano
        case 'COP':
        case 'MAD':
          return code;

        default:
          return getCurrencySymbol(code, format, locale);
      }
    }
    return code;
  }
}
