import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ErrorsFieldsComponent } from '../errors-fields/errors-fields.component';
import { InputMaskModule } from 'primeng/inputmask';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-input-mask',
    templateUrl: './input-mask.component.html',
    styleUrls: ['./input-mask.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        InputMaskModule,
        forwardRef(() => ErrorsFieldsComponent),
    ],
})
export class InputMaskComponent implements OnInit {
  @Input() parentForm: any;
  @Input() fCName: any;
  @Input() options: any;

  completed = false;

  ngOnInit() {
    this.changeWithErrors();
  }

  changeWithErrors() {
    this.parentForm.get(this.fCName).valueChanges.subscribe((val) => {
      if (
        (this.options.showErrors && val === this.options.placeholder) ||
        val === ''
      ) {
        this.completed = false;
      } else {
        this.completed = true;
      }
    });
  }
}
