import { Component, forwardRef, HostListener, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorsFieldsComponent } from '../errors-fields/errors-fields.component';
import { OnlyNumber } from '../../../../../../shared/directive/onlynumber.directive';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-multiple-input',
    templateUrl: './multiple-input.component.html',
    styleUrls: ['./multiple-input.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, NgFor, OnlyNumber, forwardRef(() => ErrorsFieldsComponent), TranslateModule]
})
export class MultipleInputComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() type: string = 'text';
  @Input() options: any = {};

  ngOnInit() {
    this.formControlSpecialInputs();
  }

  @HostListener('window:keydown', ['$event'])
  keyDown(event: KeyboardEvent) {
    const target = event.target as HTMLTextAreaElement;

    if (event.key === 'Backspace' && !target.value && this.fCName === target.id.slice(0, -1)) {
      document.getElementById(this.fCName + (parseInt(target.id.slice(-1)) - 1))?.focus();
    }
  }

  formControlSpecialInputs(): void {
    this.options.multipleInputLength.forEach((element, index) => {
      this.parentForm.get(this.fCName + index)?.valueChanges.subscribe((res) => {
        this.multiInputFormControl(index);
      });
    })
  }

  multiInputFormControl(index: number): void {
    if (!(<HTMLInputElement>document.getElementById(this.fCName + index)).value) return;

    document.getElementById(this.fCName + (index + 1))?.focus();
  }

  onFocus(event: any) {
    const currentFocus = document.getElementById(event.currentTarget.id);

    currentFocus!.style.borderColor = '#fcd100';
    currentFocus!.style.backgroundColor = '#faf4d7';
    currentFocus!.style.color = '#6F6F6F';
  }

  onBlur(event: any) {
    document.getElementById(event.currentTarget.id)!.style.backgroundColor = '#fff';

    if (!event.currentTarget.value) {
      document.getElementById(event.currentTarget.id)!.style.borderColor = '#e5e5e5';
      document.getElementById(event.currentTarget.id)!.style.color = '#e5e5e5';
    } else {
      document.getElementById(event.currentTarget.id)!.style.borderColor = '#c4c4c4';
    }
  }
}
