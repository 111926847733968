import { AuthenticationEventService } from './authenticationEvent.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationStatusService {
  private loggedIn$ = new BehaviorSubject<boolean>(false);

  constructor(private events: AuthenticationEventService) {
    // Suscribirse a los eventos de cierre de sesión y coordinar el proceso de cierre de sesión
    this.events.logout$.subscribe(({ tn }) => {
      this.loggedIn$.next(false);
      if (tn === 'carrefour') {
        window.location.href = '/login';
      }
    });
  }

  logout(tn?: string): void {
    this.events.triggerLogout(tn);
  }
}
